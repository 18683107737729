import { ReactElement, useState } from 'react';
import { Book, Config, createApplication, File, getPayload, request, Summary, useAsyncEffect } from '@topwrite/common';
import { createHistory } from '@/lib/history';
import * as models from '@/models';
import queryString from 'query-string';
import PageLoader from '@/components/page-loader';
import { CssProvider } from '@/lib/use-css';
import Page from '@/components/page';
import { LogoProvider } from '@/lib/use-logo';

async function loadLocaleData(locale: string) {
    switch (locale) {
        case 'en':
            return import('../lang/en.json');
        default:
            return import('../lang/zh-CN.json');
    }
}

export default function App() {
    const [app, setApp] = useState<ReactElement>();

    useAsyncEffect(async () => {

        try {
            const { location } = createHistory();

            let payload = getPayload();

            if (!payload) {
                //调试时远程获取
                const response = await request.get(location.toString());
                payload = response.data;
            }

            let { id, summary, file, config = {}, options = {}, lfs, pluginsCenter } = payload;

            config = Config.createFromObject(config);

            summary = Summary.create(summary);

            const book = new Book(id, config, summary);

            const initialState = {
                page: {
                    config: {
                        params: queryString.parse(location.search)
                    },
                    file: new File(file),
                    lfs,
                },
                options
            };

            const Application = await createApplication('reader', book, models, initialState, loadLocaleData, pluginsCenter);

            const app = <Application>
                <LogoProvider>
                    <CssProvider>
                        <Page />
                    </CssProvider>
                </LogoProvider>
            </Application>;

            setApp(app);
        } catch (e: any) {
            console.error(e);
            if (request.isAxiosError(e)) {
                setApp(e.response?.data || e.message);
            } else {
                setApp(e.message);
            }
        }
    }, []);

    if (app) {
        return app;
    }
    return <PageLoader />;
}
