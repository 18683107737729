import { SummaryArticle, useBook } from '@topwrite/common';
import { createContext, useContext, useMemo } from 'react';

export const ArticleContext = createContext<SummaryArticle | undefined>(undefined);

export default function useArticle() {
    return useContext(ArticleContext);
}

export function useNextArticle() {
    const { summary } = useBook();
    const article = useArticle();
    if (!article) {
        return undefined;
    }
    return useMemo(() => {
        const level = article.getLevel();
        let wasPrev = false;

        return summary.getArticle((article) => {
            if (wasPrev) {
                return !!article.getPath();
            }

            wasPrev = article.getLevel() == level;
            return false;
        });
    }, [summary, article]);
}

export function usePrevArticle() {
    const { summary } = useBook();
    const article = useArticle();
    if (!article) {
        return undefined;
    }
    return useMemo(() => {
        const level = article.getLevel();
        let prev: SummaryArticle | undefined;

        summary.getArticle((article) => {
            if (article.getLevel() == level) {
                return true;
            }
            if (article.getPath()) {
                prev = article;
            }
            return false;
        });

        return prev;
    }, [summary, article]);
}

export function usePart(article: SummaryArticle) {
    const { summary } = useBook();
    const root = article.getLevel().getRoot();
    summary.getByLevel(root);
}
