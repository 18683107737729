import { ComponentType, FC, ReactElement, ReactNode, useMemo } from 'react';
import { InjectedComponent, Summary, SummaryArticle, SummaryPart, useSelector } from '@topwrite/common';
import useArticle from '@/lib/use-article';
import useFile from '@/lib/use-file';

interface ItemProps {
    item: SummaryArticle;
    active: boolean;
    activeDescendant: boolean;
    disabled: boolean;
    children: ReactNode;
}

interface PartProps {
    part: SummaryPart;
    children: ReactNode;
}

interface ItemsProps {
    part?: SummaryPart;
    children: ReactNode;
}

interface DeprecatedCatalogProps {
    renderPart?: (props: PartProps) => ReactElement<any, any> | null;
    renderItem?: (props: ItemProps) => ReactElement<any, any> | null;
}

export interface CatalogProps {
    partComponent: ComponentType<PartProps>;
    itemComponent: ComponentType<ItemProps>;
    itemsComponent?: ComponentType<ItemsProps>;
}

const Item: FC<{ item: SummaryArticle } & Pick<CatalogProps, 'itemsComponent' | 'itemComponent'>> = ({
    item,
    itemComponent,
    itemsComponent
}) => {

    const { path: current } = useFile();
    const article = useArticle();

    const activeDescendant = useMemo(() => {
        return Boolean(article && item.getLevel().isDescendant(article.getLevel()));
    }, [item, article]);

    const active = current === item.getPath();
    const hasChildren = item.children.length > 0;

    const disabled = !item.getPath();

    const children = hasChildren ? <CatalogItems
        itemsComponent={itemsComponent}
        itemComponent={itemComponent}
        items={item.children}
    /> : null;

    return <InjectedComponent
        role={'catalog:item'}
        props={{
            item,
            active,
            disabled,
            activeDescendant,
            children
        }}
        component={itemComponent}
    />;
};

const DefaultItemsComponent: FC<ItemsProps> = ({ children }) => {
    return <ul>{children}</ul>;
};

export const CatalogItems: FC<{ items: SummaryArticle[], part?: SummaryPart, itemsComponent?: ComponentType<ItemsProps> } & Pick<CatalogProps, 'itemComponent'>> = ({
    items,
    part,
    itemsComponent = DefaultItemsComponent,
    itemComponent,
}) => {

    const children = <>
        {items.map((item) => <Item
            itemsComponent={itemsComponent}
            itemComponent={itemComponent}
            key={item.level.toString()}
            item={item}
        />)}
    </>;

    return <InjectedComponent
        role={'catalog:items'}
        props={{
            part,
            children
        }}
        component={itemsComponent}
    />;
};

const Part: FC<{ part: SummaryPart, component: ComponentType<PartProps> } & Pick<CatalogProps, 'itemComponent' | 'itemsComponent'>> = ({
    part,
    component,
    itemsComponent,
    itemComponent
}) => {
    const children = <CatalogItems
        itemsComponent={itemsComponent}
        itemComponent={itemComponent}
        items={part.articles}
        part={part}
    />;

    return <InjectedComponent
        role={'catalog:part'}
        props={{
            part,
            children
        }}
        component={component}
    />;
};

const Parts: FC<{ summary: Summary } & CatalogProps> = ({
    summary,
    partComponent,
    itemsComponent,
    itemComponent
}) => {
    if (summary.isSinglePart()) {
        return <CatalogItems
            itemsComponent={itemsComponent}
            itemComponent={itemComponent}
            items={summary.getLastPart().getArticles()}
        />;
    }

    return <>{summary.parts.map((part) => {
        return <Part
            key={part.level.toString()}
            component={partComponent}
            itemsComponent={itemsComponent}
            itemComponent={itemComponent}
            part={part}
        />;
    })}</>;
};

export default function Catalog({ ...props }: CatalogProps & DeprecatedCatalogProps) {
    const { summary } = useSelector('book');

    const { renderItem, renderPart } = props;

    if (renderItem) {
        console.warn('renderItem deprecated');
        props.itemComponent = renderItem;
    }

    if (renderPart) {
        console.warn('renderPart deprecated');
        props.partComponent = renderPart;
    }

    const { partComponent, itemsComponent, itemComponent } = props;

    return <Parts
        summary={summary}
        partComponent={partComponent}
        itemsComponent={itemsComponent}
        itemComponent={itemComponent}
    />;
}
