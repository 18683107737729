import { createContext, ReactNode, useContext } from 'react';
import { Book, request, useAsync } from '@topwrite/common';
import useFile from '@/lib/use-file';

export const CssContext = createContext<string | undefined>(undefined);

interface Props {
    readonly children: ReactNode;
}

export function CssProvider({ children }: Props) {
    const file = useFile();
    const { result, loading } = useAsync(async () => {
        const { data } = await request(file.relative(Book.style));

        return data;
    }, []);

    if (loading) {
        return null;
    }
    return <CssContext.Provider value={result}>
        {children}
    </CssContext.Provider>;
}

export default function useCss() {
    return useContext(CssContext);
}
