import { Model } from '@topwrite/common';

export interface SearchResult {
    title: string
    path: string
    body?: string
}

export type SearchHandler = (keyword: string) => Promise<SearchResult[]> | SearchResult[]

interface SearchState {
    handler?: SearchHandler
    results: SearchResult[]
    query: string
    status: 'loading' | 'error' | 'idle'
}

class Search extends Model<SearchState> {

    initialState: SearchState = {
        status: 'idle',
        results: [],
        query: ''
    };

    async *fetch(query: string) {
        query = query.trim();

        if (query) {
            yield this.setState(state => {
                state.query = query;
                state.status = 'loading';
            });

            let { handler } = yield *this.getState('search');
            if (!handler) {
                const { summary } = yield *this.getState('book');

                handler = () => {
                    const results: SearchResult[] = [];
                    summary.getArticle((article) => {
                        const path = article.getPath();
                        if (article.title.includes(query) && path) {
                            results.push({
                                title: article.title,
                                path: path
                            });
                        }
                        return false;
                    });
                    return results;
                };
            }

            try {
                const results = await handler(query);

                yield this.setState(state => {
                    state.results = results;
                    state.status = 'idle';
                });
            } catch {
                yield this.setState(state => {
                    state.results = [];
                    state.status = 'error';
                });
            }
        } else {
            yield this.setState(state => {
                state.query = query;
                state.status = 'idle';
                state.results = [];
            });
        }

    }

    *setHandler(handler?: SearchHandler) {
        yield this.setState((state) => {
            state.handler = handler;
        });
    }

}

export const search = new Search();
