import { AnchorHTMLAttributes, MouseEvent, useCallback } from 'react';
import { SummaryArticle, useSelector } from '@topwrite/common';
import { history } from '@/lib/history';
import useFile from '@/lib/use-file';

interface LinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
    to?: string | SummaryArticle;
}

export default function Link({
    children,
    to,
    className,
    ...props
}: LinkProps) {

    const file = useFile();
    const { loading } = useSelector('page');

    let p: string | undefined;
    if (to instanceof SummaryArticle) {
        p = to.getPath();
        if (!children) {
            children = to.getTitle();
        }
    } else {
        p = to;
    }

    const href = p ? file.relative(p) : '#!';

    const clickHandler = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (p && !loading) {
            const { pathname, search, hash } = e.currentTarget;

            history.push({
                pathname,
                search,
                hash
            });
        }
    }, [p, loading]);

    return <a {...props} onClick={clickHandler} className={className} href={href}>{children}</a>;
}
