import { Level, SummaryArticle, SummaryPart, useBook, useIntl } from '@topwrite/common';
import useArticle from '@/lib/use-article';
import Link from '@/components/link';
import { ReactNode } from 'react';

interface Props {
    className?: string;
    renderItem?: (item: SummaryArticle | SummaryPart) => ReactNode;
}

const defaultRenderItem = (item: SummaryArticle | SummaryPart) => {
    if (item instanceof SummaryArticle) {
        return <Link to={item} />;
    } else {
        return item.title;
    }
};

export default function Breadcrumb({ className, renderItem = defaultRenderItem }: Props) {
    const { summary } = useBook();
    const article = useArticle();
    const intl = useIntl();

    const home = intl.formatMessage({
        defaultMessage: '首页'
    });

    const children = [
        <li key='0'><Link to={'.'}>{home}</Link></li>
    ];

    if (article) {
        const level = article.getLevel();
        level.data.reduce<number[]>((prev, i) => {
            prev.push(i);
            const itemLevel = new Level(prev);
            const item = summary.getByLevel(itemLevel);
            if (item) {
                let child;
                if (itemLevel.eq(level)) {
                    child = item.title;
                } else {
                    child = renderItem(item);
                }

                children.push(<li key={itemLevel.toString()}>{child}</li>);
            }
            return prev;
        }, []);
    }

    return <ol className={className}>
        {children}
    </ol>;
}
